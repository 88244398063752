'use client';

import { ArrowBottomIcon, WEATHER_ICONS } from '@/icons';
import { useRegionList } from '@/lib/hooks';
import { Weather as WeatherT } from '@/lib/types';
import { ScrollAnimation } from '@/ui';
import { Divider, Flex, Select, Skeleton } from '@mantine/core';
import dayjs from 'dayjs';
import { useLocale, useTranslations } from 'next-intl';
import Image from 'next/image';
import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import classnames from './external-info.module.css';

type Data = {
  data: WeatherT[];
};

const initialWeather = { temp: 0, weather_code: 'clear', city: 'andijan' };

export default function Weather() {
  const t = useTranslations();
  const locale = useLocale();
  const regionList = useRegionList(locale);
  const modifiedRegionList = useMemo(
    () => Object.entries(regionList).map(([value, label]) => ({ value, label })),
    [locale]
  );

  const { data: weather, isLoading } = useSWR<Data>(['/main-page/get-weather']);

  const [value, setValue] = useState<string | null>(modifiedRegionList?.[0]?.value || null);
  const [currentWeather, setCurrentWeather] = useState<WeatherT>(weather?.data?.[0] || initialWeather);

  const getCurrentWeather = () => {
    if (!value) return;

    const selectedWeather = weather?.data?.find((weather) => weather?.city === value);
    setCurrentWeather(selectedWeather || initialWeather);
  };

  useEffect(() => {
    getCurrentWeather();
  }, [value, weather]);

  return isLoading ? (
    <Skeleton height={84} radius="0" />
  ) : (
    <ScrollAnimation>
      <Flex
        justify="center"
        align="center"
        gap="lg"
        wrap={{ base: 'wrap', md: 'nowrap' }}
        className={classnames.weather}
      >
        <Flex direction="column" gap={'sm'}>
          <Select
            data={modifiedRegionList}
            value={value}
            onChange={setValue}
            allowDeselect={false}
            rightSection={<ArrowBottomIcon />}
            classNames={classnames}
            aria-label={`${t('Ob-havo')}: ${regionList[value as string]}`}
          />
          <p>{dayjs().format('DD/MM/YYYY')}</p>
        </Flex>
        <Divider orientation="vertical" className="sm:hidden" />
        <Flex gap="sm">
          <h4>{currentWeather?.temp?.toString()?.includes('-') ? currentWeather?.temp : `+${currentWeather?.temp}`}</h4>
          {currentWeather?.weather_code?.length > 2 ? (
            <Image
              src={WEATHER_ICONS[currentWeather?.weather_code || 'clear']}
              alt={currentWeather?.weather_code}
              width={66}
              height={62}
            />
          ) : null}
        </Flex>
      </Flex>
    </ScrollAnimation>
  );
}
